<template>
    <v-card flat style="max-height: 60vh; overflow: auto">
        <v-row no-gutters>
            <v-col cols="6">
                <pre style="white-space: pre-wrap">{{ state }}</pre>
            </v-col>
            <v-col cols="6">
                <pre style="white-space: pre-wrap">{{ lines }}</pre>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'dev-sap-tab',
        created() {
        },
        data() {
            return {}
        },
        computed: {
            ...mapState({
                state: state => state.sap,
            }),
            ...mapGetters('sap', {
                visible: 'visible',
                jobs: 'jobs',
                lines: 'lines',
                routes: 'routes',
            }),
        },
    }
</script>